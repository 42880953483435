import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './App.css';
import Viewer from './components/viewer';
import { DropdownButton, Form, Offcanvas } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

import { API } from 'aws-amplify'
import Amplify from "aws-amplify";
import awsconfig from './aws-exports';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import SyncOverlay from './components/syncOverlay';
import OfflineElement from './components/offlineElement';
import { exists } from './components/apiInteraction';

Amplify.configure(awsconfig);

export enum UIType{
  title,
  item,
}

export interface ViewerObj{
   id:number;
   viewerId:number;
   online:boolean;
}

export interface OnlineListElement{
  viewerId:number;
  url: string;
  online: boolean;
  description: string;
  uiType: UIType;
  categoryId: number;
}

export interface Origin{
  cameraPosition:any;
  viewDirection:any;
  fieldOfView:any;
}

export const unavailableColour:React.CSSProperties = {textDecorationColor:"red"}
export const availableColour:React.CSSProperties = {textDecorationColor:"greenyellow"}

export const NOTAVAILABLETEXT:string = " is not available. Please check in BuildingX if the instance is running."

const qrLinkerData:any = {
  "data":{
     "listQRLinks":[
        {
          "id":10,
          "qrId":20000,
          "link":"https://timeline.360.the-digital-building.com",
          "description":"Aspern"
        },
        {
          "id":7,
          "qrId":20001,
          "link":"https://grbpjs0xsrqneuy.360viewer.bpcloud.siemens.com/aspern-tz-rb/",
          "description":"Rohbau"
        },
        {
          "id":8,
          "qrId":20002,
          "link":"https://l8tpehrtucyjonq.360viewer.bpcloud.siemens.com/aspern-tz-ht/",
          "description":"Haustechnik"
        },
        {
           "id":9,
           "qrId":20003,
           "link":"https://szqxkhiojajbrax.360viewer.bpcloud.siemens.com/aspern-tz-ug/",
           "description":"Übergabe"
        },
        
        {
           "id":11,
           "qrId":29900,
           "link":"https://timeline.360.the-digital-building.com",
           "description":"default"
        },
        {
           "id":12,
           "qrId":29901,
           "link":"https://vip.iv.navvis.com/",
           "description":"VIP"
        }
     ]
  }
}

function App() {
  const [cookies, setCookie] = useCookies(['amountOfiFrames']);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [syncStatus, setsyncStatus] = useState<boolean>(false);
  const [displaySyncOptions, setdisplaySyncOptions] = useState<boolean>(false);
  const [welcomePanel, setwelcomePanel] = useState<boolean>(false);

  const [userSelectedList, setuserSelectedList] = useState<ViewerObj[]>([]);
  const [checkMarkList, setcheckMarkList] = useState<Array<ViewerObj>>([]);

  const [linkList,setlinkList] = useState<Array<OnlineListElement>>([]);
  const [uiList, setuiList] = useState<Array<OnlineListElement>>([]);
  
  const [origin, setorigin] = useState<Origin>({cameraPosition:"",fieldOfView:"",viewDirection:""});
  const [syncSubscription, setsyncSubscription] = useState<any>(null);
  
  const checkCookieValue = (AvailList:Array<OnlineListElement>) => {
    const cookieValue: Array<ViewerObj> = cookies.amountOfiFrames;
    
    if(cookieValue){
      const newList:Array<ViewerObj> = [];
      cookieValue.forEach((item) => {
        const element = AvailList.find((listItem) => listItem.viewerId === item.viewerId);
        if(element){
          if(element.online){
            newList.push(item);
          }
          else{
            if(element?.uiType !== UIType.title){
              alert(element?.url + NOTAVAILABLETEXT);
            }
          }
        }
      })
      setuserSelectedList(newList);
    }else{
      setwelcomePanel(true);
      setCookie('amountOfiFrames',[]);
    }
    setLoaded(true);

    
  }

  

  async function checkAllLinks(toCheckList:any[]){

    let localUIList:Array<OnlineListElement> = [];
    let localCategoryId = 0;
    let localOnlineValue = false;
    
    for(let i = 0; i < toCheckList.length; i++){
      let qrLinkElement = toCheckList[i];
 
      if(!(qrLinkElement.qrId % 100 === 0) ){
        localOnlineValue = await exists(toCheckList[i].link);
      }

      if(qrLinkElement.qrId % 100 === 0){
        localCategoryId = (qrLinkElement.qrId /100) % 100;
        localUIList.push({description:qrLinkElement.description, online:false, viewerId: qrLinkElement.qrId, url: qrLinkElement.link.replace('?','&'), uiType: UIType.title, categoryId:localCategoryId});
      }else{
        localUIList.push({description:qrLinkElement.description, online:localOnlineValue, viewerId: qrLinkElement.qrId, url: qrLinkElement.link.replace('?','&'), uiType: UIType.item, categoryId:localCategoryId});
      }
    }
    setuiList(localUIList);
    setlinkList(localUIList);
    return localUIList;
  }

  async function checkAvailabliltyLinks(QRLinkList:Array<any>){  
    if(QRLinkList){
      const qrLinks = QRLinkList.filter((e: any) => e.qrId >= 20000 && e.qrId < 30000).sort((a,b) => a.qrId - b.qrId);
      checkAllLinks(qrLinks).then((newLinkList:OnlineListElement[]) => {checkCookieValue(newLinkList); readShareLink(newLinkList)});
    }
  }


  async function fetchQRLinks() {
    try {
      const QRLinkListData = await API.graphql({
        query: `
        query ListQRLinks {
          listQRLinks {
            id
            qrId
            link
            description
          }
        }
      `,
        authMode: "AWS_IAM"
      });

      const QRLinkList:any = QRLinkListData;
      const List = QRLinkList.data.listQRLinks;

      if(List){
        checkAvailabliltyLinks(List);
      }
    } catch (err) { console.log(err) }
  }

  const addViewer = (viewerId:number, newlinkList:OnlineListElement[]) => {
    let element:OnlineListElement|undefined;

    if(newlinkList.length > 0){
      element = newlinkList.find((item) => item.viewerId === viewerId);
    }else{
      element = linkList.find((item) => item.viewerId === viewerId);
    }

    if(element){
      // if(element?.online){
        let currentList = userSelectedList;
        currentList.push({id:(userSelectedList.length > 0 ? userSelectedList[userSelectedList.length-1].id+1 : 0 ),viewerId:element.viewerId,online:element.online})
        setuserSelectedList(currentList);
      // }else{
      //   alert(element?.url + NOTAVAILABLETEXT);
      // }
    }
    
  }

  const addViewerBatch = (viewerIds:number[], newlinkList:OnlineListElement[]) => {
    let elements:OnlineListElement[] = [];

    // if(newlinkList.length > 0){
    //   elements = newlinkList.filter(x => viewerIds.map(y => y).includes(x.viewerId));
    // }
    // if(elements){
    viewerIds.forEach((value:number) => {
      let item = newlinkList.find((linkListItem:OnlineListElement) => linkListItem.viewerId === value);
      if(item){
        elements.push(item);
      }
    })
    // }
    

    // if(elements){
    let currentList = userSelectedList;
    elements.forEach((value:OnlineListElement) => {
      currentList.push({id:(userSelectedList.length > 0 ? userSelectedList[userSelectedList.length-1].id+1 : 0 ),viewerId:value.viewerId,online:value.online})
    })
    registerSyncViewers(currentList);
    setuserSelectedList(currentList);
    // }
  }

  const saveView = () => {
    setCookie('amountOfiFrames',userSelectedList);
  }

  const addViewersFromChecklist = () => {
    checkMarkList.forEach((item:ViewerObj) => {
      addViewer(item.viewerId,[]);
    })
    registerSyncViewers(userSelectedList);
    setcheckMarkList([]);

    var inputs:any = document.querySelectorAll("input[type='checkbox']");
    for(var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;   
    }
    
  }

  const deleteViewer = (viewerId:number) =>{  
    let newViewers = [];
    for (let i = 0; i < userSelectedList.length; i++){
      if(userSelectedList[i].id !== viewerId){
        newViewers.push(userSelectedList[i]);
      }
    }
    setCookie('amountOfiFrames',newViewers);
    setuserSelectedList(newViewers);
  }

  const registerSyncViewers = (syncElements:Array<ViewerObj>) => {
    setsyncStatus(true);
    let sub = setInterval(() => subscribeToOrigin(syncElements),2500);
    setsyncSubscription(sub);
    setdisplaySyncOptions(false);
  }


  const subscribeToOrigin = (syncViewerList:Array<ViewerObj>) => {
    let activeElement:any = document.activeElement;

    if (activeElement!.tagName.toLowerCase() === 'iframe') {
      
      //var innerDoc = activeElement.contentDocument || activeElement.contentWindow.document;
      //let iframeInsideInnerDoc = innerDoc.getElementById("ivionElement");
      
      //activeElement = iframeInsideInnerDoc;

      let geoTransformationService = activeElement.contentWindow.IV.injector.get('GeoTransformationService');
      let camPosition = geoTransformationService.vectorToGlobal(activeElement.contentWindow.IV.getMainView().getCamera().position);
      let viewDir = activeElement.contentWindow.IV.getMainView().getViewDir();
      let fov = activeElement.contentWindow.IV.getMainView().getFov();
      let temp_origin = {cameraPosition:camPosition,viewDirection:viewDir,fieldOfView:fov};
      setorigin(temp_origin);
      
      syncViewerList.forEach((item:ViewerObj) => {
        let iframeViewerElement:any = document.getElementById(item.id+"");

        if(iframeViewerElement !== activeElement)
        {
          if(origin.cameraPosition === ""){
            iframeViewerElement.contentWindow.IV.moveToGeoLocation(temp_origin.cameraPosition, false, temp_origin.viewDirection, undefined, temp_origin.fieldOfView);
          }else{
            iframeViewerElement.contentWindow.IV.moveToGeoLocation(origin.cameraPosition, false, origin.viewDirection, undefined, origin.fieldOfView);
          }
        }
      });
    }   
  }

  const onChangeSyncButton = (checked:boolean) =>{
    if(!checked){
      if(syncSubscription){
        clearInterval(syncSubscription);
        setsyncSubscription({});
      }
    }
    setsyncStatus(checked);
    setdisplaySyncOptions(checked)
  }

  const analyseURL = () => {
    let viewers:Array<string> = [];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let shareCode = urlParams.get('share');
    
    if(shareCode){
      viewers = shareCode.split('_');
    }

    return viewers;
  }

  const shareViews = () => {
    let shareLink = "https://" + window.location.hostname + "?share=";
    console.log(userSelectedList);
    userSelectedList.forEach((value:ViewerObj,index:number) => {
      shareLink = shareLink.concat(value.viewerId.toString())
      if(index !== userSelectedList.length - 1){shareLink = shareLink.concat("_")}
    })
    console.log(shareLink);
    navigator.clipboard.writeText(shareLink);
    window.alert("The following link has been copied to your clipboard: \n" + shareLink);
  }

  const readShareLink = (newLinkList:OnlineListElement[]) => {
    //load URL parameters
    let viewers:Array<string> = analyseURL();

    if (viewers.length > 0){
      let stringViewer:Array<number> = viewers.map((value:string) => {return parseInt(value)})
      addViewerBatch(stringViewer, newLinkList);
    }
  }

  useEffect(() => {
    fetchQRLinks();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateOnlineStatus = (uniqueNumber:number) => {
    console.log("Called updateOnlineStatus with " + uniqueNumber);
    let currentList:Array<ViewerObj> = userSelectedList.map((value:ViewerObj) => {
      if(value.id === uniqueNumber){
        return { ...value, online:true};
      }else{
        return value;
      }
    });
    console.log(currentList);
    setuserSelectedList(currentList);

  }
  return (
    <div className="App">
      
      <Offcanvas show={welcomePanel} onHide={() => setwelcomePanel(false)} scroll={false} backdropClassName='Enable backdrop (default)'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Welcome to the Timeline Viewer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>In this application you can open multiple indoor viewers in parallel. Your position is synchronized between the viewers.</p> <br />
          <p>Please select the viewers you would like to see next to each other. After initialization the position and rotation are automatically synchronized.</p> <br />
          <p>The information is stored so that the previous selection will be opened directly on your next visit. The synchronization of position and rotation has to be activated by clicking on the sync dial.</p><br/>
          <p>Cookies are in use in this application, to "remember" the last configuration of viewers.</p>
        </Offcanvas.Body>
      </Offcanvas>

      <div id='viewerList'>
        {loaded && userSelectedList.map((item:ViewerObj, index:number) => {
          if(item.online){
            return (<Viewer instanceURL={linkList.find((listElement) => listElement.viewerId === item.viewerId)?.url!} deleteMe={deleteViewer} uniqueNumber={item.id} key={item.id}></Viewer>)
          }else{
            return (<OfflineElement uniqueNumber={item.id} retryURL={linkList.find((listElement) => listElement.viewerId === item.viewerId)?.url!} onlineChange={() => {updateOnlineStatus(item.viewerId)}}></OfflineElement>)
          }
        })}
      </div>
      <div id='controlPanel'>
        <div id='addViewerPanel'>
          {loaded && uiList.length !== 0 &&(
            <DropdownButton id="dropdown-item-button" title="Add new viewer">
              
              {uiList.map((item) => {
                if(item.uiType === UIType.title){
                  return (
                  <div>
                    <Dropdown.Header>{item.description}</Dropdown.Header>
                  </div>
                  )
                }else if(item.uiType === UIType.item){
                  return (
                    <div className='checkmark' title={'Scan accessible: ' + item.online}>
                      <Form.Check >
                        <Form.Check.Input type={'checkbox'} onChange={() => {let temp = checkMarkList; if(!temp.some(n => n.viewerId === item.viewerId)){temp.push({id:0, viewerId:item.viewerId, online: item.online})}; setcheckMarkList(temp)}}/>
                        <Form.Check.Label className='checkBoxFormLabel' style={item.online ? availableColour : unavailableColour}>{item.description}</Form.Check.Label>
                      </Form.Check>
                    </div>
                  )
                }
              })}

              <Dropdown.Divider />
              <Dropdown.Header>Actions</Dropdown.Header>
              <Dropdown.Item as="button" style={{backgroundColor:"#009999", color:"white", borderRadius:"5px"}} onClick={() => addViewersFromChecklist()}>Add & sync selection</Dropdown.Item>
          </DropdownButton>
          )}
        </div>
        <div id='syncButtonPanel'>
          <div>Sync</div>
          <BootstrapSwitchButton checked={syncStatus} onstyle="success" onChange={(checked:boolean) => {onChangeSyncButton(checked)}}/>
          <button className='teilenButton' onClick={() => {shareViews()}}>Share this view</button>
          <button className='teilenButton' onClick={() => {saveView()}}>Save this view</button>
        </div>
      </div>
      {displaySyncOptions && (<SyncOverlay closeMe={() => setdisplaySyncOptions(false)} registerSyncViewers={registerSyncViewers} viewerLists={userSelectedList} uiList={uiList}/>)} 
    </div>
  );
}

export default App;
