import { Button } from "react-bootstrap";

interface ViewerProps{
	deleteMe:(key:number) => any;
	instanceURL:string;
	uniqueNumber:number;
}

const Viewer = ((props:ViewerProps) => {
	// Build notes for PROD CHANGE TO HTTPS option
	const url = `https://${window.location.hostname}/element/index.html?code=${props.instanceURL}`;
	//const url = props.instanceURL;

	return (
		<div className="viewer">
			<Button className="closebutton" onClick={() => {props.deleteMe(props.uniqueNumber)}} variant="primary">Close</Button>
            <iframe id={props.uniqueNumber.toString()} style={{width:"100%"}} src={url}></iframe>
        </div>
	);
})

export default Viewer;