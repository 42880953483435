import { useState } from "react";
import { Button } from "react-bootstrap";
import { exists } from "./apiInteraction";
import "./offlineElement.css";

interface offlineElementProps{
	onlineChange:(key:number) => void;
	retryURL:string;
	uniqueNumber:number;
}

const OFFLINESTATUSTEXT = "This instance seems to be offline. Please consult your indoorviewer instance administrator.";
const ONLINESTATUSTEXT = "The connection is restored, this view will disapear, and the scan loaded.";
const OfflineElement = ((props:offlineElementProps) => {
    const [status, setStatus] = useState<string>(OFFLINESTATUSTEXT);

    const retryOnlineStatus = async () => {
        let url = props.retryURL;
        let isOnline = await exists(url);
        if(isOnline){
            setStatus(ONLINESTATUSTEXT);
            props.onlineChange(props.uniqueNumber);
        }
    }

	return (
		<div className="offlineElement">
            <p>{status}</p>
			<Button className="closebutton btn-success" onClick={() => {retryOnlineStatus()}} variant="primary">Retry online connection.</Button>
        </div>
	);
})

export default OfflineElement;