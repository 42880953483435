import { useState } from "react";
import { OnlineListElement, ViewerObj } from "../App";
import "./syncOverlay.css";
import Card from 'react-bootstrap/Card';
import { CardGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";

interface SyncOverlayProps{
	viewerLists:Array<ViewerObj>;
    uiList:Array<OnlineListElement>;
    registerSyncViewers:(syncElements:Array<ViewerObj>) => void; 
    closeMe:() => void;
}

const SyncOverlay = ((props:SyncOverlayProps) => {
    const [selectedViewerList, setselectedViewerList] = useState<ViewerObj[]>([]);

    const markViewer =  (item:ViewerObj) => {
        if(selectedViewerList.includes(item)){
            document.getElementById("card"+item.id)!.style.border = "none";

            let newList = [];
            for(let i = 0; i < selectedViewerList.length; i++){
                if(selectedViewerList[i] !== item){
                    newList.push(item);
                }
            }
            setselectedViewerList(newList);
            
        }else{
            let newList = selectedViewerList;
            newList.push(item);
            document.getElementById("card"+item.id)!.style.border = "2px solid green";
            setselectedViewerList(newList);
        }
    }

	return (
		<div id="syncOverlay">
            <div id="syncHeader">
                <div id="syncTitle">Please select the viewers that should be in sync. </div>
                <button id="closeSyncOverlayButton" title="close sync panel" onClick={props.closeMe}>X</button>
            </div>
            <div id="syncElementList">
                <CardGroup id="cardGroupId">
                    {props.viewerLists.map((item:ViewerObj) => {
                        return(
                            <Card id={"card"+item.id} border="successs" className="unselectable" unselectable="on" onClick={() => markViewer(item)}>
                                <Card.Title>{props.uiList.find((uiItem:OnlineListElement) => uiItem.viewerId ===  item.viewerId)?.description}</Card.Title>
                            </Card>
                        )
                    })}
                </CardGroup>
            </div>
            <div id="syncControlPanel">
                <Button id="syncButton" onClick={() => props.registerSyncViewers(selectedViewerList)}>Synchronize selection</Button>
            </div>
        </div>
	);
})

export default SyncOverlay;